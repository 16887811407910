import React, { FC, useState } from "react";
import imageRightPng from "images/hero-right.png";
import imageRightPng2 from "images/hero-right-2.png";
import imageRightPng3 from "images/hero-right-3.png";

import backgroundLineSvg from "images/Moon.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Next from "shared/NextPrev/Next";
import Prev from "shared/NextPrev/Prev";
import useInterval from "react-use/lib/useInterval";
import useBoolean from "react-use/lib/useBoolean";
import { getCover } from "Services/API/Get";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import { Link } from "react-router-dom";
// import cover1 from "images/cover/cover-4.png"
// import cover2 from "images/cover/cover-5.png"
// import cover1 from "images/cover/cover-u-1.png"
// import cover2 from "images/cover/cover-u-2.png"
import cover1 from "images/cover/cover_i_3.png"
import cover2 from "images/cover/cover_i_4.png"


interface Hero2DataType {
  image: string;
  heading: string;
  subHeading: string;
  btnText: string;
  btnLink: string;
}

interface Hero2DataType2 {
  image: string;
  caption1: string;
  caption2: string;

}

export interface SectionHero2Props {
  className?: string;
}

const DATA: Hero2DataType[] = [
  {
    image: imageRightPng2,
    heading: "Exclusive collection for everyone",
    subHeading: "In this season, find the best 🔥",
    btnText: "Explore now",
    btnLink: "/",
  },
  {
    image: imageRightPng3,
    heading: "Exclusive collection for everyone",
    subHeading: "In this season, find the best 🔥",
    btnText: "Explore now",
    btnLink: "/",
  },
  {
    image: imageRightPng,
    heading: "Exclusive collection for everyone",
    subHeading: "In this season, find the best 🔥",
    btnText: "Explore now",
    btnLink: "/",
  },
];
let TIME_OUT: NodeJS.Timeout | null = null;

const MCOVERS: any = [
  {
    image: cover1,
  },
  {
    image: cover2,
  }

]

const SectionHero2: FC<SectionHero2Props> = ({ className = "" }) => {
  // =================
  const [indexActive, setIndexActive] = useState(0);
  const [isRunning, toggleIsRunning] = useBoolean(true);

  const [slides, setSlides] = React.useState<Hero2DataType2[]>([{
    image: "",
    caption1: "",
    caption2: "",

  }]);
  const [lopen, setlopen] = React.useState(false);

  React.useEffect(() => {
    getSlides();
  }, []);

  const getSlides = () => {
    setlopen(true);
    getCover()
      .then((res) => {
        console.log("COver res>>>>", res.data)
        setlopen(false);


        setSlides(res.data);
      })
      .catch((error) => {
        console.log("cover error", error.response)
        setlopen(false);

      });


  };

  useInterval(
    () => {
      handleAutoNext();
    },
    isRunning ? 5500 : null
  );
  //

  const handleAutoNext = () => {
    setIndexActive((state) => {
      if (state >= slides.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= slides.length - 1) {
        return 0;
      }
      return state + 1;
    });
    handleAfterClick();
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return slides.length - 1;
      }
      return state - 1;
    });
    handleAfterClick();
  };

  const handleAfterClick = () => {
    toggleIsRunning(false);
    if (TIME_OUT) {
      clearTimeout(TIME_OUT);
    }
    TIME_OUT = setTimeout(() => {
      toggleIsRunning(true);
    }, 1000);
  };
  // =================

  const renderItem = (index: number) => {
    const isActive = indexActive === index;
    const item = slides[index];
    if (!isActive) {
      return null;
    }
    return (
      <div
        className={`nc-SectionHero2Item nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
        key={index}
      >
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 z-20 flex justify-center">
          {slides.map((_, index) => {
            const isActive = indexActive === index;
            return (
              <div
                key={index}
                onClick={() => {
                  setIndexActive(index);
                  handleAfterClick();
                }}
                className={`relative px-1 py-1.5 cursor-pointer`}
              >
                {/* <div
                  className={`relative w-20 h-1 shadow-sm rounded-md bg-white`}
                >
                  {isActive && (
                    <div
                      className={`nc-SectionHero2Item__dot absolute inset-0 bg-slate-900 rounded-md ${isActive ? " " : " "
                        }`}
                    ></div>
                  )}
                </div> */}
              </div>
            );
          })}
        </div>

        <Prev
          className="absolute left-1 sm:left-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
          btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
          svgSize="w-6 h-6"
          onClickPrev={handleClickPrev}
        />
        <Next
          className="absolute right-1 sm:right-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
          btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
          svgSize="w-6 h-6"
          onClickNext={handleClickNext}
        />

        {/* BG */}
        {/* <div className="absolute inset-0 bg-[#E3FFE6]">
         
          <img
            className="absolute w-full h-full object-cover"
            // src={backgroundLineSvg}
            src={`${IMAGE_SERVER_URL_DEV}${item?.image}`}
            alt=""
          />
        </div> */}

        <Link to={{ pathname: "/shop" }}>

          <div className="invisible md:visible" >


            <div className={`absolute inset-0 bg-[#E3FFE6] h-full bg-cover bg-center`}
              style={{
                backgroundImage: `url(${IMAGE_SERVER_URL_DEV}${item?.image})`
              }}
            >

            </div>

          </div>

          <div className="visible md:invisible" >


            <div className={`absolute inset-0 bg-[#E3FFE6] h-full bg-cover ${index != 1 ? "bg-center" : "bg-center"} lg:bg-[center_bottom_-2.8rem] `}
              style={{
                backgroundImage: `url(${MCOVERS[index]?.image})`
              }}
            >

            </div>

          </div>

          <div className="relative container pb-0 pt-14 sm:pt-20 lg:py-44 h-[580px] xl:h-[480px] min-[1920px]:h-[600px]">
            <div
              className={`relative z-[1] w-full max-w-3xl space-y-8 sm:space-y-14 nc-SectionHero2Item__left`}
            >
              {/* <div className="space-y-5 sm:space-y-6">
              <span className="nc-SectionHero2Item__subheading block text-sm md:text-lg text-slate-100 font-medium">
                {item.caption1}
              </span>
              <h2 className="nc-SectionHero2Item__heading font-semibold text-2xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl !leading-[114%] text-slate-50">
                {item.caption2}
              </h2>
            </div> */}

              {/* <ButtonPrimary
              className="nc-SectionHero2Item__button dark:bg-slate-900"
              sizeClass="py-3 px-6 sm:py-5 sm:px-9"
              href={"/shop" as any}
            >
              <span>{"Shop now"}</span>
              <span>
                <svg className="w-5 h-5 ml-2.5" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </ButtonPrimary> */}
            </div>
            <div className="mt-10 lg:mt-0 lg:absolute right-0 bottom-0 top-0 w-full max-w-2xl xl:max-w-3xl 2xl:max-w-4xl">
              <div className="w-full h-52 object-contain object-right-bottom nc-SectionHero2Item__image"></div>
              {/* <img
              className="w-full h-full object-contain object-right-bottom nc-SectionHero2Item__image"
              src={`${IMAGE_SERVER_URL_DEV}${item?.image}`}
              alt={item.caption1}
            /> */}
            </div>
          </div>
        </Link>
      </div>
    );
  };

  return <>{slides.map((_, index) => renderItem(index))}</>;
};

export default SectionHero2;
